import "./App.css";
import { Csrf, useCsrfToken } from "./Components/Auth/Csrf";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import HomeScreen from "./Components/Screens/HomeScreen";
import AdminListingManager from "./Components/Screens/Admin/AdminListingManager";
import AdminGamification from "./Components/Screens/Admin/AdminGamification";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./Components/Policies/Privacy";
import ContactUs from "./Components/ContactUs"
import DownloadRoute from "./Components/Download";

let debug = process.env.NODE_ENV === "development";
debug = false;
if (debug) {
  console.log("Debug mode:", debug);
}

ReactGA.initialize("G-68LNMC04VM", { testMode: debug });

function ReCaptchaComponent() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Execute reCAPTCHA when the component is mounted
  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }

    executeRecaptcha("homepage").then((token) => {
      return;
    });
  }, [executeRecaptcha]);

  return null;
}

function PageViewTracker() {
  const location = useLocation();

  // Send pageview with a custom path
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Landing Page",
    });
  }, [location]);

  return null;
}

function App() {
  const [notification, setNotification] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  // Show notification message above all content
  const showNotification = (message) => {
    setNotification(message);

    setTimeout(() => {
      setNotification(null);
    }, 6000); // 6 seconds timeout
  };

  // Fetch CSRF token from backend
  useEffect(() => {
    fetch("/api/get_token/")
      .then((response) => response.json())
      .then((data) => {
        setCsrfToken(data.csrf_token);
      })
      .catch((error) => {
        console.error("Error fetching CSRF token:", error);
      });
  }, []);

  if (csrfToken === null) {
    return null;
  } else if (csrfToken !== null) {
    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="https://app.termly.io/resource-blocker/a6614a36-5edb-4013-a893-753c4fc51432?autoBlock=on"
          />
        </Helmet>
        <Helmet>
  <script
    type="text/javascript"
    id="hs-script-loader"
    async
    defer
    src="https://js.hs-scripts.com/48290206.js"
  ></script>
</Helmet>

        <Csrf csrfToken={csrfToken}>
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <ReCaptchaComponent />
            <Router>
              <PageViewTracker />
              <Routes>
                <Route exact path="/" element={<HomeScreen />} />
                <Route
                  exact
                  path="/admin/listings"
                  element={<AdminListingManager />}
                />
                <Route
                  exact
                  path="/privacy"
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path="/download"
                  element={<DownloadRoute />}
                />
                <Route
                  exact
                  path="/contact"
                  element={<ContactUs />}
                />
                <Route
                  exact
                  path="/admin/gamify"
                  element={<AdminGamification />}
                />
              </Routes>
            </Router>
          </GoogleReCaptchaProvider>
        </Csrf>
      </>
    );
  }
}

export default App;
