import { useState, useEffect } from "react";
import Nav from "./Global/Nav";
import Header from "./Global/Header";
import Footer from "./Global/Footer";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [csrfToken, setCsrfToken] = useState("");

  useEffect(() => {
    fetch("/api/get_token/")
      .then((response) => response.json())
      .then((data) => {
        setCsrfToken(data.csrf_token);
      })
      .catch((error) => {
        console.error("Error fetching CSRF token:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch(
        "https://api.hsforms.com/submissions/v3/integration/submit/48290206/bac9e356-b0a3-48fd-b829-6397f52b6e4a",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify({
            fields: [
              { name: "firstname", value: formData.name },
              { name: "email", value: formData.email },
              { name: "contact_us_body", value: formData.message },
            ],
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      setResponseMessage("Message sent successfully!");
    } catch (error) {
      setResponseMessage("Failed to send message. Please try again.");
    }
    setIsSubmitting(false);
  };

  return (
    <div className="flex-1 h-full flex flex-col min-h-screen">
      <div
        className="w-full min-h-20 flex flex-col md:flex-row justify-between shadow-md"
        style={{ background: "#2da" }}
      >
        <div>
          <div className="flex w-22 md:w-44 p-6">
            <a href="/">
              <img
                className="my-auto w-full"
                src="/static/react_frontend/images/logos/logo-white.svg"
                alt="pawpal logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col items-center p-6 flex-1 "
        style={{ height: "100%" }}
      >
        <h1 className="text-2xl font-bold mb-4 pt-6">Contact Us</h1>
        <p>Get in touch via the form below, or send us an email at</p>
        <a href="mailto:support@getpawpal.co.uk" className="text-teal-600">
          support@getpawpal.co.uk
        </a>
        <form
          onSubmit={handleSubmit}
          className="w-full max-w-lg flex flex-col gap-4 pt-10"
        >
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="border p-2"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
            className="border p-2"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            required
            className="border p-2"
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className=" text-white p-2 rounded tealButton"
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>
        {responseMessage && <p className="mt-4">{responseMessage}</p>}
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
