import { useState, useEffect } from "react";
import Nav from "./Global/Nav";
import Header from "./Global/Header";
import Footer from "./Global/Footer";
import { Link, useNavigate } from "react-router-dom";

const DownloadRoute = () => {
    const navigate = useNavigate();

    useEffect(() => {
      document.title = "Download PawPal";
    }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS detection
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

    // Android detection
    const isAndroid = /android/i.test(userAgent);
    console.log("ismobile",isIOS, isAndroid)

    if (isIOS) {
      window.location.href = "https://apps.apple.com/gb/app/pawpal-uk/id6738281574"
    } else if (isAndroid) {
      window.location.href = "/"
    } else {
      window.location.href = "/"

    }
  }, [navigate]);

  return null; // No UI rendered
}

export default DownloadRoute
