import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="w-full min-h-20 flex flex-col md:flex-row justify-between py-3 ">
      <div className="m-auto text-center md:text-left flex flex-col">
        <p>PawPal &copy; {year}</p>
        <div className="flex space-x-1 mx-auto md:ml-0 my-2">
          <a href="https://www.instagram.com/the.pawpal.app/" target="_blank">
            <InstagramIcon fontSize="large"/>
          </a>
          <a href="https://www.tiktok.com/@pawpalapp/" target="_blank" style={{transform: 'translateY(-3px)'}}>
          <img src="/static/react_frontend/images/icons/tiktok.svg" height={40} width={40}/>
          </a>
        </div>
        
      </div>

      <div className="m-auto flex flex-col ">
        <a href="#" className="termly-display-preferences m-auto md:mr-0 mt-2 text-center md:text-right">
          Consent Preferences
        </a>
        <a href="/privacy" className="m-auto md:mr-0 my-2 text-center md:text-right">
          Privacy Policy
        </a>
        <a
          href="/contact"
          className="m-auto md:mr-0 mb-2" 
        >
          Contact Us
        </a>
        <p  className="m-auto md:mr-0 mb-2 text-xs text-gray-200 flex-wrap whitespace-pre-wrap" >BC Media Ltd T/A PawPal</p>
      </div>
    </footer>
  );
}

export default Footer;
